import React, { Suspense } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Container,
  Stack,
  CircularProgress,
} from "@mui/material";
import ApiKeyDialog from "./dialog/ApiKeyDialog";
import { hasApiKey } from "../api/tracker";
import { FaHome, FaList } from "react-icons/fa";
import { GiChart, GiNotebook, GiScrewdriver } from "react-icons/gi";
import { Route, Routes } from "react-router-dom";
import Home from "./page/Home";
import Notes from "./page/Notes";
import NavButton from "./nav/NavButton";
import AppRefreshButton from "./button/AppRefreshButton";
import DialogContainer from "./dialog/DialogContainer";
import Feeds from "./page/Feeds";
import Utils from "./page/Utils";
import Pumps from "./page/Pumps";
import Records from "./page/Records";
import AppBackButton from "./button/AppBackButton";
import useRefreshOnSiteUpdate from "../hooks/useRefreshOnSiteUpdate";
import Sleep from "./page/Sleep";
import { useSyncTimeWithStore } from "../hooks/useSyncTimeWithStore";
import NursingEstimates from "./page/NursingEstimates";

const StatsAsync = React.lazy(() => import("./page/Stats"));

const Stats = () => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <StatsAsync />
    </Suspense>
  );
};

export default function App() {
  useRefreshOnSiteUpdate();
  useSyncTimeWithStore();
  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <AppBar className="app-top-bar" position="sticky">
        <Toolbar>
          <AppBackButton />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Rita Tracker
          </Typography>
          <AppRefreshButton />
        </Toolbar>
      </AppBar>
      <Container
        className="app-content-container"
        sx={{ flexGrow: 1 }}
        disableGutters
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/notes" element={<Notes />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/records/feed" element={<Feeds />} />
          <Route path="/records/pumps" element={<Pumps />} />
          <Route path="/records/sleep" element={<Sleep />} />
          <Route
            path="/records/nursing-estimates"
            element={<NursingEstimates />}
          />
          <Route path="/utils" element={<Utils />} />
          <Route path="/records" element={<Records />} />
        </Routes>
        {!hasApiKey() && <ApiKeyDialog />}
        <DialogContainer />
      </Container>
      <AppBar
        className="app-bottom-bar"
        position="sticky"
        color="primary"
        sx={{ bottom: 0 }}
      >
        <Stack direction="row" justifyContent="space-evenly" padding={0}>
          <NavButton startIcon={<FaHome />} to="/">
            Home
          </NavButton>
          <NavButton startIcon={<GiNotebook />} to="/notes">
            Notes
          </NavButton>
          <NavButton startIcon={<FaList />} to="/records">
            Records
          </NavButton>
          <NavButton startIcon={<GiChart />} to="/stats">
            Stats
          </NavButton>
          <NavButton startIcon={<GiScrewdriver />} to="/utils">
            Utils
          </NavButton>
        </Stack>
      </AppBar>
    </Box>
  );
}
