import { useEffect, useMemo } from "react";

export function useOnWindowFocus(cb: (evt: PageTransitionEvent) => void) {
  useEffect(() => {
    window.addEventListener("pageshow", cb);
    return () => window.removeEventListener("pageshow", cb);
  }, [cb]);
}

export function useLongPressEvent(
  shortPress: () => void,
  longPress: () => void
) {
  return useMemo(() => {
    let timeout: any = 0;
    const mouseUp = (evt: MouseEvent) => {
      evt.returnValue = false;
      evt.preventDefault();
      clearTimeout(timeout);
      shortPress();
    };
    const mouseDown = (evt: MouseEvent) => {
      evt.returnValue = false;
      evt.preventDefault();
      timeout = setTimeout(() => {
        longPress();
      }, 1000);
    };
    return {
      mouseUp,
      mouseDown,
    };
  }, [shortPress, longPress]);
}
