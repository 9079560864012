import React, { useLayoutEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";

export default function AppBackButton() {
  const navigation = useNavigate();
  const location = useLocation();
  const [showBackButton, setShowBackButton] = useState(false);

  useLayoutEffect(() => {
    setShowBackButton(location.pathname.split("/").length > 2);
  }, [location]);

  if (!showBackButton) {
    return null;
  }

  return (
    <IconButton
      onClick={() => navigation(-1)}
      color="inherit"
      sx={{ marginRight: 1 }}
    >
      <IoArrowBack />
    </IconButton>
  );
}
