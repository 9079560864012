import React, { useEffect } from "react";

declare global {
  interface HTMLElementEventMap {
    ["long-press"]: () => void;
  }
}

export default function useOnLongPress(
  ref: React.MutableRefObject<HTMLElement>,
  cb: () => void
) {
  useEffect(() => {
    const targetRef = ref.current;
    if (!targetRef) {
      return;
    }
    ref.current.addEventListener("long-press", cb);
    return () => targetRef.removeEventListener("long-press", cb);
  }, [ref, cb]);
}
