import React, { useEffect, useMemo, useRef, useState } from "react";

function isElementInViewport(el: HTMLElement) {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */ &&
    rect.right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /* or $(window).width() */
  );
}

export function useVirtualizedScrollingHelper(
  endOfListElementRef: React.MutableRefObject<HTMLElement>,
  batchSize: number
) {
  const [renderLimit, setRenderLimit] = useState(() => batchSize);
  useEffect(() => {
    const conditionalLoadMore = () => {
      if (isElementInViewport(endOfListElementRef.current)) {
        setRenderLimit((val) => val + batchSize);
      }
    };
    window.addEventListener("scroll", conditionalLoadMore);
    return () => window.removeEventListener("scroll", conditionalLoadMore);
  }, [batchSize, endOfListElementRef]);
  return {
    count: renderLimit,
  };
}

export function useVirtualizedArray<T>(arr: T[], batchSize: number) {
  const endOfListRef = useRef<HTMLDivElement>();
  const { count } = useVirtualizedScrollingHelper(endOfListRef, batchSize);
  const truncatedArray = useMemo(
    () => (arr ? arr.slice(0, count) : []),
    [arr, count]
  );
  const isTruncated = !!arr && truncatedArray.length != arr.length;

  const endElement = (
    <div
      style={{ display: isTruncated ? undefined : "none" }}
      ref={endOfListRef}
    >
      Loading more...
    </div>
  );

  return {
    truncatedArray,
    endElement,
  };
}
