import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
  useTheme,
} from "@mui/material";
import React, { useCallback } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface MultiselectProps {
  label: string;
  options: string[];
  value: string[];
  disabled?: boolean;
  onChange: (value: string[]) => void;
  multiple?: boolean;
}

export default function Multiselect({
  label,
  options,
  value,
  disabled,
  onChange,
  multiple = true,
}: MultiselectProps) {
  const theme = useTheme();
  const onSelectChange = useCallback(
    ({ target: { value } }) => {
      onChange(typeof value === "string" ? value.split(",") : value);
    },
    [onChange]
  );

  return (
    <FormControl sx={{ m: 1 }} disabled={disabled}>
      <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        multiple={multiple}
        value={value}
        disabled={disabled}
        onChange={onSelectChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected: string[]) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {options.map((name) => (
          <MenuItem
            key={name}
            value={name}
            style={getStyles(name, options, theme)}
          >
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
