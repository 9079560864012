import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
} from "@mui/material";
import TextField from "../form/TextField";

export default function ApiKeyDialog() {
  const [inputValue, setInputValue] = useState("");
  return (
    <Dialog open>
      <DialogTitle>API Key Needed</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            label="API Key"
            type="password"
            value={inputValue}
            onChange={setInputValue}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            localStorage.setItem("apiKey", inputValue);
            location.reload();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
