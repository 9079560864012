import { Feed } from "../api/tracker";

const dateFormatter = new Intl.DateTimeFormat("en-US", {
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "2-digit",
  hourCycle: "h23",
});

export const getDisplayTime = (rawDate: string) => {
  const date = new Date(rawDate);
  return dateFormatter.format(date);
};

export const formatLastBottleFeed = (lastBottleFeeding: Feed) =>
  !lastBottleFeeding
    ? ""
    : `${getDisplayTime(lastBottleFeeding.date)} ${
        lastBottleFeeding.mlsFromBottle
      }ml bottle`;

export const formatLastBreastFeed = (lastBreastFeeding: Feed) =>
  !lastBreastFeeding
    ? ""
    : lastBreastFeeding.feedType === "weighted"
      ? `${getDisplayTime(lastBreastFeeding.date)} ${
          lastBreastFeeding.mlsFromBottle
        }ml on breast`
      : `${getDisplayTime(lastBreastFeeding.date)} ${
          lastBreastFeeding.minutesOnBreast
        }m on breast`;
