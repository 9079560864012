import React from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { GiBabyBottle, GiBabyFace, GiOilPump, GiScales } from "react-icons/gi";
import { babyTrackerApi } from "../../data/babyTrackerApi";
import { useOnErrorNotification } from "../../hooks/notifications";
import { useDispatch } from "react-redux";
import { dialogSlice } from "../../data/dialogSlice";

export default function FeedingActionList() {
  const { enqueueSnackbar } = useSnackbar();
  const [createFeed] = babyTrackerApi.useCreateFeedMutation();
  const [createPump] = babyTrackerApi.useCreatePumpMutation();
  const onError = useOnErrorNotification();
  const dispatch = useDispatch();
  const feedItems = [
    {
      icon: <GiBabyBottle />,
      text: "Bottle feed",
      action: () =>
        dispatch(
          dialogSlice.actions.openStartFeedDialog({
            title: "",
            inputTitle: "",
            valueKey: "startMlsFromBottle",
            onSave: ({ value, notes, date }) =>
              createFeed({
                startMlsFromBottle: value,
                notes,
                date: date.toISOString(),
                completed: false,
                feedStart: date.toISOString(),
              }).then(() => {
                enqueueSnackbar("Bottle feed started", {
                  variant: "success",
                });
              }, onError),
          })
        ),
    },
    {
      icon: <GiBabyFace />,
      text: "Breast feed",
      action: () =>
        dispatch(
          dialogSlice.actions.openBreastFeedDialog({
            title: "",
            inputTitle: "",
            valueKey: "minutesOnBreast",
            onSave: ({ updatedRecord }) =>
              createFeed({
                feedType: "breast-percent",
                date: updatedRecord.date ?? new Date().toISOString(),
                notes: updatedRecord.notes ?? "",
                completed: true,
                ...updatedRecord,
              }),
          })
        ),
    },
    {
      icon: <GiOilPump />,
      text: "Pump",
      action: () =>
        dispatch(
          dialogSlice.actions.openPumpDialog({
            title: "How did pumping go?",
            inputTitle: "Volume pumped? (ml)",
            valueKey: "pumpedMls",
            onSave: ({ value, notes, date }) =>
              createPump({
                pumpedMls: value,
                notes,
                date: date.toISOString(),
              }).then(() => {
                enqueueSnackbar("Pumped recorded", {
                  variant: "success",
                });
              }, onError),
          })
        ),
    },
    {
      icon: <GiScales />,
      text: "Weighted feed",
      action: () => {
        dispatch(
          dialogSlice.actions.openStartWeightedFeedDialog({
            title: "How much does Rita weigh?",
            inputTitle: "Weight (grams)",
            valueKey: "startingWeight",
            onSave: ({ value, notes, date }) =>
              createFeed({
                startingWeight: value,
                notes,
                feedStart: date.toISOString(),
                completed: false,
                feedType: "weighted",
                date: date.toISOString(),
                minutesOnBreast: 0,
                mlsFromBottle: 0,
                startMlsFromBottle: 0,
              }).then(() => {
                enqueueSnackbar("Weight recorded", {
                  variant: "success",
                });
              }, onError),
          })
        );
      },
    },
    {
      icon: <GiBabyFace />,
      text: "Breast feed (timed)",
      action: () =>
        dispatch(
          dialogSlice.actions.openFeedDialog({
            title: "How long did Rita breast feed?",
            inputTitle: "Minutes fed",
            valueKey: "minutesOnBreast",
            onSave: ({ value, notes, date }) =>
              createFeed({
                minutesOnBreast: value,
                notes,
                date: date.toISOString(),
                completed: true,
              }).then(() => {
                enqueueSnackbar("Breast time recorded", {
                  variant: "success",
                });
              }, onError),
          })
        ),
    },
  ];

  return (
    <>
      <ListSubheader>Feeding</ListSubheader>
      {feedItems.map((item) => (
        <ListItem disablePadding key={item.text}>
          <ListItemButton onClick={item.action}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        </ListItem>
      ))}
    </>
  );
}
