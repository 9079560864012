import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const timeSlice = createSlice({
  name: "time",
  initialState: {
    currentTimeInMinutes: 0,
  },
  reducers: {
    updateTime(state, { payload }: PayloadAction<number>) {
      state.currentTimeInMinutes = payload;
    },
  },
});
