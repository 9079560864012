import React, { useState } from "react";
import dayjs from "dayjs";
import StandardFormDialog from "./StandardFormDialog";
import { useSnackbar } from "notistack";
import { useOnErrorNotification } from "../../hooks/notifications";
import { BaseRecord } from "../../api/tracker";
import { dialogSlice } from "../../data/dialogSlice";
import { useDispatch } from "react-redux";

export interface FeedDialogProps<T extends BaseRecord = BaseRecord> {
  title: string;
  inputTitle: string;
  onSave: (ctx: {
    value: number;
    notes: string;
    date: Date;
    isEdit: boolean;
    completed: boolean;
    updatedRecord?: Partial<T>;
  }) => Promise<any>;
  onDelete?: (id: number) => Promise<any>;
  valueKey: keyof T;
  record?: T;
  encodeValue?: (value: any) => number;
  decodeValue?: (value: any) => number;
}

export default function FeedDialog({
  title,
  inputTitle,
  onSave,
  record,
  valueKey,
  encodeValue,
  decodeValue,
  onDelete,
}: FeedDialogProps) {
  const isEdit = !!record;
  const [formData, setFormData] = useState(() => {
    if (!record) {
      return {
        time: dayjs(),
        value: "",
        notes: "",
      };
    }

    return {
      time: dayjs(record.date),
      value: `${
        decodeValue ? decodeValue(record[valueKey]) : record[valueKey]
      }`,
      notes: record.notes,
    };
  });

  const dispatch = useDispatch();

  const onClose = () => dispatch(dialogSlice.actions.closeDialog());

  const { enqueueSnackbar } = useSnackbar();
  const onError = useOnErrorNotification();

  return (
    <StandardFormDialog
      title={title}
      onClose={onClose}
      edit={isEdit}
      fullWidth
      onSave={async () => {
        const value = encodeValue
          ? encodeValue(+formData.value)
          : +formData.value;
        try {
          await onSave({
            value,
            notes: formData.notes,
            date: formData.time.toDate(),
            isEdit,
            completed: true,
          });
        } catch (error) {
          onError(error);
        }

        onClose();
      }}
      fields={[
        {
          type: isEdit ? "datetime" : "time",
          maxTime: dayjs(),
          label: "Time",
          dataKey: "time",
        },
        {
          type: "number",
          label: inputTitle,
          dataKey: "value",
          inputMode: "decimal",
        },
        {
          type: "multiline",
          label: "Notes (optional)",
          dataKey: "notes",
        },
      ]}
      onDelete={async () => {
        try {
          await onDelete(record.id);
          enqueueSnackbar("Record removed", {
            variant: "success",
          });
          onClose();
        } catch (error) {
          onError(error);
        }
      }}
      value={formData}
      onChange={setFormData}
    />
  );
}
