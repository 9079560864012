import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Feed } from "../api/tracker";
import { babyTrackerApi } from "../data/babyTrackerApi";
import { dialogSlice } from "../data/dialogSlice";
import { useOnErrorNotification } from "./notifications";

export function useOpenBottleFeedDialog(record?: Feed) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [createFeed] = babyTrackerApi.useCreateFeedMutation();
  const [updateFeed] = babyTrackerApi.useUpdateFeedMutation();
  const onError = useOnErrorNotification();

  const openDialog = useCallback(() => {
    const editMode = !!record;
    dispatch(
      dialogSlice.actions.openFeedDialog({
        title: "How much did Rita drink?",
        inputTitle: "ml drank",
        valueKey: "mlsFromBottle",
        record,
        onSave: ({ value, notes, date }) =>
          (editMode ? updateFeed : createFeed)({
            id: record?.id,
            mlsFromBottle: value,
            notes,
            date: date.toISOString(),
            completed: true,
          }).then(() => {
            enqueueSnackbar(`Bottle ${editMode ? "updated" : "recorded"}`, {
              variant: "success",
            });
          }, onError),
      })
    );
  }, [record, createFeed, updateFeed, dispatch, enqueueSnackbar, onError]);

  return openDialog;
}

export function useOpenBreastFeedDialog(record?: Feed) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [createFeed] = babyTrackerApi.useCreateFeedMutation();
  const [updateFeed] = babyTrackerApi.useUpdateFeedMutation();
  const onError = useOnErrorNotification();

  const openDialog = useCallback(() => {
    const editMode = !!record;
    dispatch(
      dialogSlice.actions.openFeedDialog({
        title: "How long did Rita breast feed?",
        inputTitle: "Minutes fed",
        valueKey: "minutesOnBreast",
        record,
        onSave: ({ value, notes, date }) =>
          (editMode ? updateFeed : createFeed)({
            id: record?.id,
            minutesOnBreast: value,
            notes,
            date: date.toISOString(),
            completed: true,
          }).then(() => {
            enqueueSnackbar(
              `Breast time ${editMode ? "updated" : "recorded"}`,
              {
                variant: "success",
              }
            );
          }, onError),
      })
    );
  }, [record, createFeed, updateFeed, dispatch, enqueueSnackbar, onError]);

  return openDialog;
}
