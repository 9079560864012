import React, { useCallback, useState } from "react";
import dayjs from "dayjs";
import { babyTrackerApi } from "../../data/babyTrackerApi";
import { useSnackbar } from "notistack";
import { useOnErrorNotification } from "../../hooks/notifications";
import StandardFormDialog from "./StandardFormDialog";
import { getBaseUrl, Note, uploadFile } from "../../api/tracker";

const availableTags = ["Milestone", "Thoughts", "Bath"];

export interface NoteDialogProps {
  onClose: () => void;
  record: Note;
}

export default function NoteDialog({ onClose, record }: NoteDialogProps) {
  const isEditMode = !!record;
  const [formData, setFormData] = useState(() => ({
    time: dayjs(record ? record.date : undefined),
    notes: record ? record.notes : "",
    tags: (record && record.tags) || [],
    rawImage: ((record &&
      record.imageId &&
      `${getBaseUrl()}/files/file/${record.imageId}`) ||
      undefined) as string | File,
  }));

  const [createNote] = babyTrackerApi.useCreateNoteMutation();
  const [updateNote] = babyTrackerApi.useUpdateNoteMutation();
  const [deleteNote] = babyTrackerApi.useDeleteNoteMutation();
  const { enqueueSnackbar } = useSnackbar();
  const onError = useOnErrorNotification();

  const onSave = useCallback(
    async ({ tags, notes, date }) => {
      const fields = {
        tags,
        notes,
        date,
      };

      if (formData.rawImage && typeof formData.rawImage !== "string") {
        const fileInfo = await uploadFile(formData.rawImage);
        fields["imageId"] = fileInfo.id;
      }
      try {
        if (isEditMode) {
          await updateNote({
            ...fields,
            id: record.id,
          });
          enqueueSnackbar("Note updated", {
            variant: "success",
          });
        } else {
          await createNote(fields);
          enqueueSnackbar("Note created", {
            variant: "success",
          });
        }
      } catch (error) {
        onError(error);
      } finally {
        onClose();
      }
    },
    [
      formData,
      createNote,
      updateNote,
      enqueueSnackbar,
      isEditMode,
      onClose,
      onError,
      record,
    ]
  );

  const onDelete = useCallback(
    () =>
      deleteNote(record.id).then(() => {
        enqueueSnackbar("Note deleted", {
          variant: "success",
        });
        onClose();
      }),
    [record, enqueueSnackbar, deleteNote, onClose]
  );

  return (
    <StandardFormDialog
      fullWidth
      title={isEditMode ? "Edit note" : "Take note"}
      edit={isEditMode}
      onClose={onClose}
      value={formData}
      onChange={setFormData}
      fields={[
        {
          type: "datetime",
          maxDateTime: dayjs(),
          label: "Time",
          dataKey: "time",
        },
        {
          type: "multiselect",
          label: "Tags",
          options: availableTags,
          dataKey: "tags",
        },
        {
          type: "image",
          label: "Upload image",
          dataKey: "rawImage",
        },
        {
          type: "multiline",
          label: "Notes",
          dataKey: "notes",
        },
      ]}
      onDelete={onDelete}
      onSave={() =>
        onSave({
          tags: formData.tags,
          notes: formData.notes,
          date: formData.time.toDate(),
        })
      }
    />
  );
}
