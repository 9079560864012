import { useEffect, useRef } from "react";
import { siteApi } from "../data/siteApi";

export default function useRefreshOnSiteUpdate() {
  const { data } = siteApi.useGetVersionQuery();
  const lastVersionRef = useRef<string>();

  useEffect(() => {
    if (!data) {
      return;
    }

    if (!lastVersionRef.current) {
      lastVersionRef.current = data.version;
      return;
    }

    if (lastVersionRef.current !== data.version) {
      window.location.reload();
    }
  }, [data]);
}
