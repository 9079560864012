import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import React from "react";

interface DateTimeFieldProps {
  label: string;
  maxDateTime?: Dayjs;
  value: Dayjs;
  onChange: (value: Dayjs) => void;
}

export default function DateTimeField({
  label,
  value,
  onChange,
  maxDateTime,
}: DateTimeFieldProps) {
  return (
    <MobileDateTimePicker
      maxDateTime={maxDateTime}
      value={value}
      label={label}
      onChange={onChange}
      closeOnSelect={true}
    />
  );
}
