import { createSelector } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";
import { BaseRecord, Feed, NursingEstimate } from "../../api/tracker";
import { AppState } from "../rootReducer";
import {
  allFeedingsSelector,
  allNursingEstimatesSelector,
  allPumpsSelector,
  allSleepsSelector,
} from "./dataSelectors";
import { currentDaySelector } from "./timeSelectors";

const chartSelector = (state: AppState) => state.chart;

export const chartDurationSelector = createSelector(
  chartSelector,
  (chart) => chart.chartDuration
);
export const chartTypeSelector = createSelector(
  chartSelector,
  (chart) => chart.chartType
);

const sumReducer = (prev: number, next: number) => prev + next;
const countReducer = (prev: number) => prev + 1;
const averageReducer = (
  prev: number,
  next: number,
  idx: number,
  arr: number[]
) => prev + next / arr.length;

interface DataPoint {
  x: string;
  y: number;
}

const alwaysTrue = () => true;

function* iterateDays(now: Dayjs, daysAgo: number) {
  for (let i = 0; i < daysAgo; ++i) {
    yield now.subtract(i, "days").startOf("day").toISOString();
  }
}

function groupByDate<T extends BaseRecord>(
  records: T[],
  dataField: keyof T,
  daysAgo: number,
  currentTime: number,
  valueReducer: (prevValue: number, nextValue: number) => number,
  predicate: (record: T) => boolean = alwaysTrue
) {
  const groupedRecords: Record<string, DataPoint[]> = {};
  const now = dayjs(currentTime);
  for (const day of iterateDays(now, daysAgo)) {
    groupedRecords[day] = records
      .filter(predicate)
      .filter((rec) => Number.isFinite(rec[dataField]))
      .filter((rec) => dayjs(rec.date).startOf("day").toISOString() === day)
      .map((rec) => ({
        x: rec.date,
        y: +rec[dataField],
      }));
  }

  return Object.entries(groupedRecords).map(([x, grouped]) => ({
    x,
    y: grouped.reduce((prev, curr) => valueReducer(prev, curr.y), 0),
  }));
}

const getNursingEstimateForDate = (estimates: NursingEstimate[]) => {
  return (date: string) => {
    const estimateIndex = estimates.findIndex((item) => item.date < date);
    const currentEstimateIndex = Math.max(0, estimateIndex);
    const estimate = estimates[currentEstimateIndex];

    return (
      estimate ?? {
        duration: 10,
        mls: 30,
        created: date,
        date,
        id: 0,
        notes: "",
      }
    );
  };
};

const getNursingEstimateFactorForDate = (estimates: NursingEstimate[]) => {
  const estimateForDate = getNursingEstimateForDate(estimates);
  return (date: string) => {
    const estimate = estimateForDate(date);
    return estimate.mls / estimate.duration;
  };
};

export const getNursingEstimateForDateSelector = createSelector(
  allNursingEstimatesSelector,
  getNursingEstimateForDate
);

const breastEstimateSelector = createSelector(
  [allFeedingsSelector, allNursingEstimatesSelector],
  (feeds, nursingEstimates) => {
    const estimateFactorGetter =
      getNursingEstimateFactorForDate(nursingEstimates);
    const estimateForDate = getNursingEstimateForDate(nursingEstimates);

    return feeds
      .filter(
        (feed) =>
          Number.isFinite(feed.minutesOnBreast) && feed.feedType !== "weighted"
      )
      .map((feed) => {
        if (feed.feedType === "breast-percent") {
          return {
            ...feed,
            mlsFromBottle:
              (feed.minutesOnBreast * estimateForDate(feed.date)?.mls) / 100,
          };
        }

        if (Number.isFinite(feed.minutesOnBreast)) {
          return {
            ...feed,
            mlsFromBottle:
              feed.minutesOnBreast * estimateFactorGetter(feed.date),
          };
        }

        return feed;
      });
  }
);

const feedsWithBreastEstimateSelector = createSelector(
  [allFeedingsSelector, allNursingEstimatesSelector],
  (feeds, nursingEstimates) => {
    const estimateFactorGetter =
      getNursingEstimateFactorForDate(nursingEstimates);
    const estimateForDate = getNursingEstimateForDate(nursingEstimates);

    return feeds.map((feed) => {
      if (feed.feedType === "breast-percent") {
        return {
          ...feed,
          mlsFromBottle:
            (feed.minutesOnBreast * estimateForDate(feed.date)?.mls) / 100,
        };
      }

      if (
        Number.isFinite(feed.minutesOnBreast) &&
        feed.feedType !== "weighted"
      ) {
        return {
          ...feed,
          mlsFromBottle: feed.minutesOnBreast * estimateFactorGetter(feed.date),
        };
      }

      return feed;
    });
  }
);

export const bottleFeedsDataSelector = createSelector(
  [allFeedingsSelector, chartDurationSelector, currentDaySelector],
  (feeds, daysAgo, currentTime) =>
    groupByDate(
      feeds,
      "mlsFromBottle",
      daysAgo,
      currentTime,
      sumReducer,
      (feed) => feed.feedType !== "weighted"
    )
);

export const weightedFeedsDataSelector = createSelector(
  [allFeedingsSelector, chartDurationSelector, currentDaySelector],
  (feeds, daysAgo, currentTime) =>
    groupByDate(
      feeds,
      "mlsFromBottle",
      daysAgo,
      currentTime,
      sumReducer,
      (feed) => feed.feedType === "weighted"
    )
);

export const allFeedsDataSelector = createSelector(
  [feedsWithBreastEstimateSelector, chartDurationSelector, currentDaySelector],
  (feeds, daysAgo, currentTime) =>
    groupByDate(feeds, "mlsFromBottle", daysAgo, currentTime, sumReducer)
);

export const pumpDataSelector = createSelector(
  [allPumpsSelector, chartDurationSelector, currentDaySelector],
  (pumps, daysAgo, currentTime) =>
    groupByDate(pumps, "pumpedMls", daysAgo, currentTime, sumReducer)
);

export const breastEstimateByDaySelector = createSelector(
  [breastEstimateSelector, chartDurationSelector, currentDaySelector],
  (feeds, daysAgo, currentTime) =>
    groupByDate(feeds, "mlsFromBottle", daysAgo, currentTime, sumReducer)
);

export const breastOutputDataSelector = createSelector(
  [pumpDataSelector, weightedFeedsDataSelector, breastEstimateByDaySelector],
  (pumps, weightedFeeds, nursingEstimates) => {
    return pumps.map((pump, idx) => ({
      x: pump.x,
      y:
        pump.y + (weightedFeeds[idx]?.y ?? 0) + (nursingEstimates[idx]?.y ?? 0),
    }));
  }
);

export const breastFeedingTimeDataSelector = createSelector(
  [
    allFeedingsSelector,
    chartDurationSelector,
    currentDaySelector,
    allNursingEstimatesSelector,
  ],
  (feeds, daysAgo, currentTime, nursingEstimates) =>
    groupByDate(
      feeds.map((feed) =>
        feed.feedType === "breast-percent"
          ? {
              ...feed,
              minutesOnBreast:
                (feed.minutesOnBreast / 100) *
                getNursingEstimateForDate(nursingEstimates)(feed.date).duration,
            }
          : feed
      ),
      "minutesOnBreast",
      daysAgo,
      currentTime,
      sumReducer
    )
);

export const breastPumpsPerDayDataSelector = createSelector(
  [allPumpsSelector, chartDurationSelector, currentDaySelector],
  (pumps, daysAgo, currentTime) =>
    groupByDate(pumps, "pumpedMls", daysAgo, currentTime, countReducer)
);

export const volumePerPumpDataSelector = createSelector(
  [breastPumpsPerDayDataSelector, pumpDataSelector],
  (pumpsPerDay, pumpData) =>
    pumpsPerDay.map((v, idx) => ({
      x: v.x,
      y: pumpData[idx].y / v.y,
    }))
);

const isNightTimeFeed = (feed: Feed) => {
  const date = dayjs(feed.date);
  return date.hour() >= 22 || date.hour() <= 6;
};

export const nightFeedDataSelector = createSelector(
  [feedsWithBreastEstimateSelector, chartDurationSelector, currentDaySelector],
  (feeds, duration, currentTime) =>
    groupByDate(
      feeds.filter(isNightTimeFeed),
      "mlsFromBottle",
      duration,
      currentTime,
      sumReducer
    )
);

export const dayFeedDataSelector = createSelector(
  [feedsWithBreastEstimateSelector, chartDurationSelector, currentDaySelector],
  (feeds, duration, currentTime) =>
    groupByDate(
      feeds.filter((feed) => !isNightTimeFeed(feed)),
      "mlsFromBottle",
      duration,
      currentTime,
      sumReducer
    )
);

function* patchDataPoints(master: DataPoint[], patchee: DataPoint[]) {
  let patcheeOffset = 0;
  for (let i = 0; i < master.length; ++i) {
    const curr = master[i];
    const currOther = patchee[i - patcheeOffset];

    if (currOther === undefined || curr.x !== currOther.x) {
      ++patcheeOffset;
      yield {
        x: curr.x,
        y: 0,
      };
    } else {
      yield currOther;
    }
  }
}

export const dayVsFeedPercentDataSelector = createSelector(
  [dayFeedDataSelector, nightFeedDataSelector],
  (day, night) => {
    night = [...patchDataPoints(day, night)];
    return day.map((dayValue, idx) => ({
      x: dayValue.x,
      y: dayValue.y / (dayValue.y + night[idx].y),
    }));
  }
);

export const timeOfDayScatterSelector = createSelector(
  [allFeedingsSelector, chartDurationSelector, currentDaySelector],
  (feeds, duration, currentDay) => {
    const sinceTime = dayjs(currentDay).subtract(duration, "days");
    return feeds
      .filter((feed) => feed.mlsFromBottle && sinceTime.isBefore(feed.date))
      .map((feed) => ({
        ...feed,
        dateDayJs: dayjs(feed.date),
      }))
      .reduce(
        (prev, feed) => {
          if (
            prev.length > 0 &&
            prev[prev.length - 1].dateDayJs.diff(feed.dateDayJs, "minutes") < 60
          ) {
            const newArr = prev.slice(0, prev.length - 2);
            const lastElem = prev[prev.length - 1];
            newArr.push({
              ...lastElem,
              mlsFromBottle: lastElem.mlsFromBottle + feed.mlsFromBottle,
            });
            return newArr;
          }

          return [...prev, feed];
        },
        <(Feed & { dateDayJs: Dayjs })[]>[]
      )
      .map((feed) => ({
        x: (Math.round(feed.dateDayJs.hour() / 3) * 3) % 24,
        y: feed.mlsFromBottle,
      }));
  }
);

export const timeOfDayScatterLineFitSelector = createSelector(
  timeOfDayScatterSelector,
  (scatterPoints) => {
    const groupedData = scatterPoints.reduce(
      (prev, point) => {
        if (!prev[point.x]) {
          return {
            ...prev,
            [point.x]: [point.y],
          };
        }

        return {
          ...prev,
          [point.x]: [...prev[point.x], point.y],
        };
      },
      <Record<number, number[]>>{}
    );

    return Object.entries(groupedData)
      .sort((a, b) => +a[0] - +b[0])
      .reduce(
        (prev, curr) => {
          return [
            ...prev,
            {
              x: +curr[0],
              y: curr[1].reduce(averageReducer, 0),
            },
          ];
        },
        <{ x: number; y: number }[]>[]
      );
  }
);

export const sleepLineChartDataSelector = createSelector(
  [allSleepsSelector, chartDurationSelector, currentDaySelector],
  (sleeps, duration, currentDay) =>
    groupByDate(
      sleeps,
      "durationMinutes",
      duration,
      currentDay,
      sumReducer
    ).map((res) => ({
      ...res,
      y: res.y / 60,
    }))
);
