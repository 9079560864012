import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { AppState } from "../rootReducer";

const timeDataSelector = (state: AppState) => state.time;

export const currentTimeSelector = createSelector(timeDataSelector, (data) =>
  dayjs(data.currentTimeInMinutes * 60 * 1000)
);

export const currentDaySelector = createSelector(
  currentTimeSelector,
  (currentTime) => +currentTime.startOf("day").toDate()
);
