import React, { useState } from "react";
import dayjs from "dayjs";
import StandardFormDialog from "./StandardFormDialog";
import { useSnackbar } from "notistack";
import { useOnErrorNotification } from "../../hooks/notifications";
import { Feed } from "../../api/tracker";
import { dialogSlice } from "../../data/dialogSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../data/rootReducer";
import type { Option } from "../form/SingleSelect";
import { getNursingEstimateForDateSelector } from "../../data/selectors/chartSelectors";
import { babyTrackerApi } from "../../data/babyTrackerApi";

export interface FeedDialogProps {
  record?: Feed;
  onSave: (ctx: { updatedRecord: Partial<Feed> }) => Promise<any>;
  onDelete?: (id: number) => Promise<any>;
}

const percentSelects = [200, 175, 150, 125, 100, 75, 50, 25];

export default function BreastFeedDialog({
  onSave,
  record,
  onDelete,
}: FeedDialogProps) {
  const isEdit = !!record;
  const [formData, setFormData] = useState(() => {
    if (record) {
      return {
        ...record,
        time: dayjs(record.date),
        breastPercent: `${record.minutesOnBreast}%`,
      };
    }

    return {
      time: dayjs(),
      breastPercent: "100%",
      notes: "",
    };
  });

  babyTrackerApi.useGetAllNursingEstimatesQuery();
  const getBreastEstimateForDate = useAppSelector(
    getNursingEstimateForDateSelector
  );
  const latestBreastFeedEstimate = getBreastEstimateForDate(
    formData.time.toISOString()
  );

  const options = percentSelects.map((percent) =>
    latestBreastFeedEstimate
      ? {
          label: `${percent}% (${latestBreastFeedEstimate.mls * (percent / 100)}ml)`,
          value: `${percent}%`,
        }
      : `${percent}%`
  ) as string[] | Option[];

  const dispatch = useDispatch();

  const onClose = () => dispatch(dialogSlice.actions.closeDialog());

  const { enqueueSnackbar } = useSnackbar();
  const onError = useOnErrorNotification();

  return (
    <StandardFormDialog
      title="Breast Feed"
      onClose={onClose}
      edit={isEdit}
      fullWidth
      onSave={async () => {
        const {
          breastPercent: __breastPercent,
          time: __time,
          ...dataWithoutBreastPercent
        } = formData;
        const updatedRecord: Partial<Feed> = {
          ...dataWithoutBreastPercent,
          minutesOnBreast: parseInt(formData.breastPercent),
          completed: true,
          date: formData.time.toISOString(),
        };
        try {
          await onSave({ updatedRecord });
          enqueueSnackbar({
            variant: "success",
            message: isEdit ? "Breast feed updated" : "Breast feed recorded",
          });
        } catch (error) {
          onError(error);
        }

        onClose();
      }}
      fields={[
        {
          type: isEdit ? "datetime" : "time",
          maxTime: dayjs(),
          label: "Time",
          dataKey: "time",
        },
        {
          type: "select",
          options,
          label: "Breast",
          dataKey: "breastPercent",
        },
        {
          type: "multiline",
          label: "Notes (optional)",
          dataKey: "notes",
        },
      ]}
      onDelete={async () => {
        try {
          await onDelete(record.id);
          enqueueSnackbar("Record removed", {
            variant: "success",
          });
          onClose();
        } catch (error) {
          onError(error);
        }
      }}
      value={formData}
      onChange={setFormData}
    />
  );
}
