import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Feed, Note, NursingEstimate, Pump, Sleep } from "../api/tracker";
import { FeedDialogProps } from "../components/dialog/FeedDialog";

export const dialogSlice = createSlice({
  name: "dialog",
  initialState: {
    openDialog: "",
    dialogArgs: undefined as any,
  },
  reducers: {
    openNoteDialog: (
      state,
      action: PayloadAction<{
        record: Note;
      }>
    ) => {
      state.openDialog = "note";
      state.dialogArgs = action.payload || {};
    },
    openStartFeedDialog: (
      state,
      action: PayloadAction<FeedDialogProps<Feed>>
    ) => {
      state.openDialog = "start-feed";
      state.dialogArgs = action.payload || {};
    },
    openStartWeightedFeedDialog: (
      state,
      action: PayloadAction<FeedDialogProps<Feed>>
    ) => {
      state.openDialog = "start-weighted-feed";
      state.dialogArgs = action.payload || {};
    },
    openBreastFeedDialog: (
      state,
      action: PayloadAction<FeedDialogProps<Feed>>
    ) => {
      state.openDialog = "breast-feed";
      state.dialogArgs = action.payload || {};
    },
    openWeightedFeedDialog: (
      state,
      action: PayloadAction<FeedDialogProps<Feed>>
    ) => {
      state.openDialog = "weighted-feed";
      state.dialogArgs = action.payload || {};
    },
    openFeedDialog: (state, action: PayloadAction<FeedDialogProps<Feed>>) => {
      state.openDialog = "feed";
      state.dialogArgs = action.payload || {};
    },
    openSleepDialog: (state, action: PayloadAction<FeedDialogProps<Sleep>>) => {
      state.openDialog = "sleep";
      state.dialogArgs = action.payload || {};
    },
    openPumpDialog: (state, action: PayloadAction<FeedDialogProps<Pump>>) => {
      state.openDialog = "feed";
      state.dialogArgs = action.payload || {};
    },
    openPottyDialog: (state, action) => {
      state.openDialog = "potty";
      state.dialogArgs = action.payload || {};
    },
    openNursingEstimateDialog: (
      state,
      action: PayloadAction<{
        record: NursingEstimate;
      }>
    ) => {
      state.openDialog = "nursing-estimate";
      state.dialogArgs = action.payload || {};
    },
    closeDialog: (state) => {
      state.openDialog = "";
      state.dialogArgs = {};
    },
  },
});

dialogSlice.actions.openNoteDialog();
