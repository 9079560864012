import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { BaseRecord, CreateRecord } from "../api/tracker";

const capitalize = (s: string) => s[0].toUpperCase() + s.slice(1);

export function makeCrudEndpoints<Ttype extends BaseRecord>() {
  return function <
    B extends EndpointBuilder<any, any, any>,
    Tag extends string,
    KeyName extends string
  >(builder: B, key: KeyName, urlBase: string, tag: Tag) {
    const getAllQuery = builder.query<Ttype[], void>({
      query: () => ({
        url: `/${urlBase.toLowerCase()}`,
      }),
      providesTags: [tag],
    });

    const deleteQuery = builder.mutation<any, number>({
      query: (id) => ({
        url: `/${urlBase.toLowerCase()}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [tag],
    });

    const updateQuery = builder.mutation<any, Partial<Ttype>>({
      query: (data) => ({
        url: `/${urlBase.toLowerCase()}/${data.id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: [tag],
    });

    const createQuery = builder.mutation<any, CreateRecord<Ttype>>({
      query: (data) => ({
        url: `/${urlBase.toLowerCase()}`,
        body: data,
        method: "POST",
      }),
      invalidatesTags: [tag],
    });

    type ReturnValue = {
      [K in KeyName as `getAll${Capitalize<KeyName>}s`]: typeof getAllQuery;
    } & {
      [K in KeyName as `delete${Capitalize<KeyName>}`]: typeof deleteQuery;
    } & {
      [K in KeyName as `update${Capitalize<KeyName>}`]: typeof updateQuery;
    } & {
      [K in KeyName as `create${Capitalize<KeyName>}`]: typeof createQuery;
    };

    return {
      [`getAll${capitalize(key)}s`]: getAllQuery,
      [`delete${capitalize(key)}`]: deleteQuery,
      [`update${capitalize(key)}`]: updateQuery,
      [`create${capitalize(key)}`]: createQuery,
    } as ReturnValue;
  };
}
