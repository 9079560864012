import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import {
  GiBabyBottle,
  GiBabyFace,
  GiHourglass,
  GiScales,
} from "react-icons/gi";
import { useDispatch } from "react-redux";
import { Feed } from "../../api/tracker";
import { babyTrackerApi } from "../../data/babyTrackerApi";
import { dialogSlice } from "../../data/dialogSlice";
import { useVirtualizedArray } from "../../hooks/virtualScroll";
import { durationToMinutes, minutesToDuration } from "../../util/duration";
import { getDisplayTime } from "../../util/formatting";
import { useSnackbar } from "notistack";
import { useAppSelector } from "../../data/rootReducer";
import { getNursingEstimateForDateSelector } from "../../data/selectors/chartSelectors";

interface FeedProps {
  feed: Feed;
}

interface FeedRowProps extends FeedProps {
  subTitle: string;
  Icon: React.ComponentType<{ style: React.CSSProperties }>;
  onEdit: () => void;
}

function FeedRow({ feed, subTitle, Icon, onEdit }: FeedRowProps) {
  return (
    <Card>
      <CardActionArea onClick={onEdit}>
        <CardContent>
          <Stack direction="row" marginBottom={1} alignItems="center">
            <Typography variant="h6" component="span">
              <Icon style={{ position: "relative", top: "2px" }} />{" "}
              {getDisplayTime(feed.date)}
            </Typography>
            <Typography sx={{ paddingLeft: 1 }}>{subTitle}</Typography>
          </Stack>
          <Typography>{feed.notes}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export function StartedBottleFeed({ feed }: FeedProps) {
  const dispatch = useDispatch();
  const [updateFeed] = babyTrackerApi.useUpdateFeedMutation();
  const [deleteFeed] = babyTrackerApi.useDeleteFeedMutation();
  const { enqueueSnackbar } = useSnackbar();
  const onEdit = useCallback(() => {
    dispatch(
      dialogSlice.actions.openFeedDialog({
        title: "How much did Rita drink?",
        inputTitle: "ml drank",
        valueKey: "mlsFromBottle",
        onDelete: deleteFeed,
        onSave: ({ value, date, notes }) =>
          updateFeed({
            id: feed.id,
            date: date.toISOString(),
            mlsFromBottle: value,
            notes,
            completed: true,
          }).then(() => {
            enqueueSnackbar("Bottle feed completed", {
              variant: "success",
            });
          }),
        record: {
          ...feed,
          mlsFromBottle: feed.startMlsFromBottle,
        },
      })
    );
  }, [dispatch, feed, deleteFeed, updateFeed]);

  return (
    <Card>
      <CardActionArea onClick={onEdit}>
        <CardContent>
          <Stack direction="row" marginBottom={1} alignItems="center">
            <Typography variant="h6" component="span">
              <GiHourglass style={{ position: "relative", top: "2px" }} />{" "}
              {getDisplayTime(feed.feedStart)}
            </Typography>
            <Typography sx={{ paddingLeft: 1 }}>
              {feed.startMlsFromBottle}ml started
            </Typography>
          </Stack>
          <Typography>{feed.notes}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function BottleFeed({ feed }: FeedProps) {
  const dispatch = useDispatch();
  const [updateFeed] = babyTrackerApi.useUpdateFeedMutation();
  const [deleteFeed] = babyTrackerApi.useDeleteFeedMutation();
  const onEdit = useCallback(() => {
    dispatch(
      dialogSlice.actions.openFeedDialog({
        title: "How much did Rita drink?",
        inputTitle: "ml drank",
        valueKey: "mlsFromBottle",
        onDelete: deleteFeed,
        onSave: ({ value, date, notes }) =>
          updateFeed({
            id: feed.id,
            date: date.toISOString(),
            mlsFromBottle: value,
            notes,
          }),
        record: feed,
      })
    );
  }, [dispatch, feed, deleteFeed, updateFeed]);

  return (
    <FeedRow
      feed={feed}
      onEdit={onEdit}
      subTitle={`${feed.mlsFromBottle}ml`}
      Icon={GiBabyBottle}
    />
  );
}

function BreastFeed({ feed }: FeedProps) {
  const dispatch = useDispatch();
  const [updateFeed] = babyTrackerApi.useUpdateFeedMutation();
  const [deleteFeed] = babyTrackerApi.useDeleteFeedMutation();
  const onEdit = useCallback(() => {
    dispatch(
      dialogSlice.actions.openFeedDialog({
        title: "How long did Rita breast feed?",
        inputTitle: "Minutes fed",
        valueKey: "minutesOnBreast",
        encodeValue: minutesToDuration,
        decodeValue: durationToMinutes,
        onDelete: deleteFeed,
        onSave: ({ value, date, notes }) =>
          updateFeed({
            id: feed.id,
            date: date.toISOString(),
            minutesOnBreast: value,
            notes,
          }),
        record: feed,
      })
    );
  }, [dispatch, feed, deleteFeed, updateFeed]);

  babyTrackerApi.useGetAllNursingEstimatesQuery();
  const getBreastEstimateForDate = useAppSelector(
    getNursingEstimateForDateSelector
  );
  const currentEstimate = getBreastEstimateForDate(feed.date);

  if (currentEstimate) {
    const factor = currentEstimate.mls / currentEstimate.duration;
    return (
      <FeedRow
        feed={feed}
        onEdit={onEdit}
        subTitle={`${feed.minutesOnBreast}m (${Number((feed.minutesOnBreast * factor).toFixed(2))}ml)`}
        Icon={GiBabyFace}
      />
    );
  }

  return (
    <FeedRow
      feed={feed}
      onEdit={onEdit}
      subTitle={`${feed.minutesOnBreast}m`}
      Icon={GiBabyFace}
    />
  );
}

function BreastFeedPercent({ feed }: FeedProps) {
  const dispatch = useDispatch();
  const [updateFeed] = babyTrackerApi.useUpdateFeedMutation();
  const [deleteFeed] = babyTrackerApi.useDeleteFeedMutation();
  const onEdit = useCallback(() => {
    dispatch(
      dialogSlice.actions.openBreastFeedDialog({
        title: "",
        inputTitle: "",
        valueKey: "minutesOnBreast",
        encodeValue: minutesToDuration,
        decodeValue: durationToMinutes,
        onDelete: deleteFeed,
        onSave: ({ updatedRecord }) => updateFeed(updatedRecord),
        record: feed,
      })
    );
  }, [dispatch, feed, deleteFeed, updateFeed]);
  babyTrackerApi.useGetAllNursingEstimatesQuery();
  const getBreastEstimateForDate = useAppSelector(
    getNursingEstimateForDateSelector
  );
  const currentEstimate = getBreastEstimateForDate(feed.date);
  if (currentEstimate) {
    return (
      <FeedRow
        feed={feed}
        onEdit={onEdit}
        subTitle={`${feed.minutesOnBreast}% (${currentEstimate.mls * (feed.minutesOnBreast / 100)}ml)`}
        Icon={GiBabyFace}
      />
    );
  }

  return (
    <FeedRow
      feed={feed}
      onEdit={onEdit}
      subTitle={`${feed.minutesOnBreast}%`}
      Icon={GiBabyFace}
    />
  );
}

export function WeightedFeed({ feed }: FeedProps) {
  const dispatch = useDispatch();
  const [commitWeightedFeed] = babyTrackerApi.useCommitWeightedFeedMutation();
  const [updateFeed] = babyTrackerApi.useUpdateFeedMutation();
  const [deleteFeed] = babyTrackerApi.useDeleteFeedMutation();
  const subtitle = !feed.completed
    ? `Starting weight: ${feed.startingWeight}g`
    : `${feed.mlsFromBottle}mls (${feed.startingWeight}g to ${feed.startingWeight + feed.mlsFromBottle}g)`;
  return (
    <FeedRow
      feed={feed}
      subTitle={subtitle}
      Icon={feed.completed ? GiScales : GiHourglass}
      onEdit={() => {
        dispatch(
          dialogSlice.actions.openWeightedFeedDialog({
            title: "Weighted Feed",
            onDelete: deleteFeed,
            onSave: ({ updatedRecord }) => {
              if (feed.completed) {
                return updateFeed(updatedRecord);
              } else {
                return commitWeightedFeed({
                  ...updatedRecord,
                  completed: true,
                });
              }
            },
            record: feed,
            inputTitle: "Weight (grams)",
            valueKey: "mlsFromBottle",
          })
        );
      }}
    />
  );
}

function Feed({ feed }: FeedProps) {
  if (feed.feedType === "weighted") {
    return <WeightedFeed feed={feed} />;
  }

  if (feed.feedType === "breast-percent") {
    return <BreastFeedPercent feed={feed} />;
  }

  if (Number.isFinite(feed.mlsFromBottle)) {
    return <BottleFeed feed={feed} />;
  }

  if (Number.isFinite(feed.minutesOnBreast)) {
    return <BreastFeed feed={feed} />;
  }

  if (Number.isFinite(feed.startMlsFromBottle)) {
    return <StartedBottleFeed feed={feed} />;
  }

  return null;
}

export default function Feeds() {
  const { isLoading, isError, data } = babyTrackerApi.useGetAllFeedsQuery();
  const { truncatedArray, endElement } = useVirtualizedArray(data, 50);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Failed to load feedings</div>;
  }

  return (
    <>
      <Paper variant="elevation" square>
        <Stack direction="row" alignItems="center" paddingX={2} paddingY={1}>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Feed records
          </Typography>
        </Stack>
      </Paper>
      <Stack gap={1} padding={1}>
        {truncatedArray.map((feed) => (
          <Feed feed={feed} key={feed.id} />
        ))}
        {endElement}
      </Stack>
    </>
  );
}
