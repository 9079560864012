export interface BaseRecord {
  id: number;
  created: string;
  date: string;
  notes: string;
}

export interface Feed extends BaseRecord {
  minutesOnBreast?: null | number;
  mlsFromBottle?: null | number;
  startMlsFromBottle?: null | number;
  completed: boolean;
  feedStart?: string;
  startingWeight?: null | number;
  feedType?: "bottle" | "breast" | "weighted" | "breast-percent" | null;
}

export interface Note extends BaseRecord {
  tags: string[];
  imageId?: null | number;
}

export interface Potty extends BaseRecord {
  output: string[];
}

export interface Pump extends BaseRecord {
  pumpedMls: number;
}

export interface Sleep extends BaseRecord {
  durationMinutes: number;
}

export interface NursingEstimate extends BaseRecord {
  mls: number;
  duration: number;
}

export interface UploadedFile {
  id: number;
  created: string;
  mimetype: string;
}

export type CreateRecord<T> = Omit<T, "id" | "created">;

export const getBaseUrl = () => {
  return "https://baby.jecko.dev/api";
};

const apiKey = window.localStorage.getItem("apiKey");
export const hasApiKey = () => !!apiKey;

export const authWithAirtable = () =>
  fetch(`${getBaseUrl()}/auth/airtable`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "include",
    body: JSON.stringify({ key: apiKey }),
    headers: {
      "Content-Type": "application/json",
    },
  });

export const uploadFile = async (file: File): Promise<UploadedFile> => {
  const formData = new FormData();
  formData.append("file", file, file.name);

  const res = await fetch(`${getBaseUrl()}/files/upload`, {
    method: "POST",
    body: formData,
    credentials: "include",
  });
  return res.json();
};
