import { useSnackbar } from "notistack";
import { useCallback } from "react";

export function useOnErrorNotification() {
  const { enqueueSnackbar } = useSnackbar();
  return useCallback(
    (err: any) => {
      enqueueSnackbar(`Failed to save: ${err}`);
    },
    [enqueueSnackbar]
  );
}
