import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Theme,
  useTheme,
} from "@mui/material";
import React, { useCallback } from "react";

export type Option = {
  label: string;
  value: string;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: any, personName: any[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface SingleSelectProps {
  label: string;
  options: string[] | Option[];
  value: string;
  disabled?: boolean;
  onChange: (value: string[]) => void;
}

export default function SingleSelect({
  label,
  options,
  value,
  disabled,
  onChange,
}: SingleSelectProps) {
  const theme = useTheme();
  const onSelectChange = useCallback(
    ({ target: { value } }) => {
      onChange(typeof value === "string" ? value.split(",") : value);
    },
    [onChange]
  );

  return (
    <FormControl sx={{ m: 1 }} disabled={disabled}>
      <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        value={value}
        disabled={disabled}
        onChange={onSelectChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        MenuProps={MenuProps}
      >
        {options.map((option) => {
          if (typeof option === "object") {
            return (
              <MenuItem
                key={option.value}
                value={option.value}
                style={getStyles(option.value, options, theme)}
              >
                {option.label}
              </MenuItem>
            );
          }
          return (
            <MenuItem
              key={option}
              value={option}
              style={getStyles(name, options, theme)}
            >
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
