import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import duration from "dayjs/plugin/duration";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";

dayjs.extend(duration);
const TimerDoneSound = new Audio("/sounds/magic-mallet.mp3");

const timers = [1, 5, 15, 20, 30, 60];

const twoDigits = (val: number) => {
  return val.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });
};

export default function Timer() {
  const [isActive, setIsActive] = useState(false);
  const [displayTime, setDisplayTime] = useState("");
  const intervalRef = useRef<any>();
  const targetTime = useRef<Dayjs>();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => () => clearInterval(intervalRef.current), []);

  const cancelTimer = () => {
    clearInterval(intervalRef.current);
    setDisplayTime("");
    setIsActive(false);
  };

  const timerDone = () => {
    cancelTimer();
    TimerDoneSound.play();
    enqueueSnackbar("Timer done");
  };

  const getStartTimer = (minutes) => () => {
    clearInterval(intervalRef.current);
    targetTime.current = dayjs().add(minutes, "minutes");
    const onInterval = () => {
      const ms = targetTime.current.diff(dayjs());
      if (ms <= 0) {
        timerDone();
        return;
      }

      const duration = dayjs.duration(ms, "milliseconds");

      const timeFormat = `${twoDigits(duration.minutes())}:${twoDigits(
        duration.seconds()
      )}`;
      setDisplayTime(timeFormat);
    };
    intervalRef.current = setInterval(onInterval, 500);
    onInterval();
    setIsActive(true);
  };

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {isActive ? displayTime : "Timer"}
        </Typography>
        {isActive ? (
          <Button
            color="inherit"
            variant="contained"
            sx={{ padding: 1 }}
            onClick={cancelTimer}
          >
            Cancel
          </Button>
        ) : (
          <Grid
            spacing={1}
            container
            alignItems="start"
            justifyContent="stretch"
          >
            {timers.map((timer) => (
              <Grid item xs key={timer} sx={{ flexGrow: 0 }}>
                <Button
                  color="inherit"
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={getStartTimer(timer)}
                >
                  {timer}m
                </Button>
              </Grid>
            ))}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}
