import React, { useState } from "react";
import dayjs from "dayjs";
import StandardFormDialog from "./StandardFormDialog";
import { useSnackbar } from "notistack";
import { useOnErrorNotification } from "../../hooks/notifications";
import { Feed } from "../../api/tracker";
import { dialogSlice } from "../../data/dialogSlice";
import { useDispatch } from "react-redux";

export interface FeedDialogProps {
  record: Feed;
  onSave: (ctx: { updatedRecord: Feed }) => Promise<any>;
  onDelete?: (id: number) => Promise<any>;
}

export default function WeightedFeedDialog({
  onSave,
  record,
  onDelete,
}: FeedDialogProps) {
  const isEdit = !!record;
  const [formData, setFormData] = useState(() => {
    if (record.completed) {
      return {
        ...record,
        time: dayjs(record.date),
        endingWeight: record.startingWeight + record.mlsFromBottle,
      };
    }

    return {
      ...record,
      time: dayjs(record.date),
      endingWeight: record.startingWeight + 90,
      minutesOnBreast: dayjs().diff(dayjs(record.date), "minute"),
    };
  });

  const mlsFromBottle = formData.endingWeight - formData.startingWeight;

  const dispatch = useDispatch();

  const onClose = () => dispatch(dialogSlice.actions.closeDialog());

  const { enqueueSnackbar } = useSnackbar();
  const onError = useOnErrorNotification();

  return (
    <StandardFormDialog
      title="Weighted Feed"
      onClose={onClose}
      edit={isEdit}
      fullWidth
      onSave={async () => {
        const {
          time: __time,
          endingWeight: __ending,
          ...recordWithoutTime
        } = formData;
        const updatedRecord = {
          ...recordWithoutTime,
          mlsFromBottle,
          completed: true,
          date: formData.time.toISOString(),
        };

        try {
          await onSave({ updatedRecord });
          enqueueSnackbar({
            variant: "success",
            message: isEdit
              ? "Weighted feed updated"
              : "Weighted feed recorded",
          });
        } catch (error) {
          onError(error);
        }

        onClose();
      }}
      fields={[
        {
          type: isEdit ? "datetime" : "time",
          maxTime: dayjs(),
          label: "Time",
          dataKey: "time",
        },
        {
          type: "number",
          label: "Start weight? (grams)",
          dataKey: "startingWeight",
          inputMode: "decimal",
        },
        {
          type: "number",
          label: "End weight? (grams)",
          dataKey: "endingWeight",
          inputMode: "decimal",
        },
        {
          type: "number",
          label: "Amount fed? (ml)",
          dataKey: "mlsFromBottle",
          inputMode: "decimal",
          disabled: true,
        },
        {
          type: "duration",
          label: "Minutes on breast",
          dataKey: "minutesOnBreast",
        },
        {
          type: "multiline",
          label: "Notes (optional)",
          dataKey: "notes",
        },
      ]}
      onDelete={async () => {
        try {
          await onDelete(record.id);
          enqueueSnackbar("Record removed", {
            variant: "success",
          });
          onClose();
        } catch (error) {
          onError(error);
        }
      }}
      value={{ ...formData, mlsFromBottle }}
      onChange={setFormData}
    />
  );
}
