import React, { useState } from "react";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { babyTrackerApi } from "../../data/babyTrackerApi";
import StandardFormDialog from "./StandardFormDialog";
import { Potty } from "../../api/tracker";

interface PottyEditDialogProps {
  record: Potty;
  onClose: () => void;
}

export default function PottyEditDialog({
  record,
  onClose,
}: PottyEditDialogProps) {
  const [formData, setformData] = useState(() => ({
    time: dayjs(record.date),
    output: record.output,
  }));

  const [updatePotty] = babyTrackerApi.useUpdatePottyMutation();
  const [deletePotty] = babyTrackerApi.useDeletePottyMutation();

  return (
    <StandardFormDialog
      edit
      fields={[
        { type: "time", maxTime: dayjs(), label: "time", dataKey: "time" },
        {
          type: "multiselect-cb",
          label: "Output",
          options: ["Poo", "Pee"],
          dataKey: "output",
        },
      ]}
      onClose={onClose}
      title="Edit last potty"
      onChange={setformData}
      value={formData}
      onSave={() => {
        updatePotty({
          id: record.id,
          output: formData.output,
          date: formData.time.toDate().toISOString(),
        }).then(onClose);
      }}
      onDelete={() => deletePotty(record.id).then(onClose)}
    />
  );
}

PottyEditDialog.propTypes = {
  onDataModified: PropTypes.func,
  onClose: PropTypes.func,
  record: PropTypes.object,
};
