import React, { useCallback } from "react";
import { dialogSlice } from "../../data/dialogSlice";
import { useAppSelector, useThunkDispatch } from "../../data/rootReducer";
import FeedDialog from "./FeedDialog";
import NoteDialog from "./NoteDialog";
import NursingEstimateDialog from "./NursingEstimateDialog";
import PottyEditDialog from "./PottyEditDialog";
import SleepDialog from "./SleepDialog";
import StartFeedDialog from "./StartFeedDialog";
import StartWeightedFeedDialog from "./StartWeightedFeedDialog";
import WeightedFeedDialog from "./WeightedFeedDialog";
import BreastFeedDialog from "./BreastFeedDialog";

export default function DialogContainer() {
  const currentDialog = useAppSelector((state) => state.dialog.openDialog);
  const dialogArgs = useAppSelector((state) => state.dialog.dialogArgs);
  const dispatch = useThunkDispatch();

  const onClose = useCallback(() => {
    dispatch(dialogSlice.actions.closeDialog());
  }, [dispatch]);

  switch (currentDialog) {
    case "note":
      return <NoteDialog {...dialogArgs} onClose={onClose} />;
    case "start-feed":
      return <StartFeedDialog {...dialogArgs} onClose={onClose} />;
    case "start-weighted-feed":
      return <StartWeightedFeedDialog {...dialogArgs} onClose={onClose} />;
    case "weighted-feed":
      return <WeightedFeedDialog {...dialogArgs} onClose={onClose} />;
    case "breast-feed":
      return <BreastFeedDialog {...dialogArgs} onClose={onClose} />;
    case "feed":
      return <FeedDialog {...dialogArgs} onClose={onClose} />;
    case "potty":
      return <PottyEditDialog {...dialogArgs} onClose={onClose} />;
    case "nursing-estimate":
      return <NursingEstimateDialog {...dialogArgs} onClose={onClose} />;
    case "sleep":
      return <SleepDialog {...dialogArgs} onClose={onClose} />;
    default:
      return null;
  }
}
