import React, { useCallback, useState } from "react";
import dayjs from "dayjs";
import { babyTrackerApi } from "../../data/babyTrackerApi";
import { useSnackbar } from "notistack";
import { useOnErrorNotification } from "../../hooks/notifications";
import StandardFormDialog from "./StandardFormDialog";
import { NursingEstimate } from "../../api/tracker";

export interface NursingEstimateDialogProps {
  onClose: () => void;
  record: NursingEstimate;
}

export default function NursingEstimateDialog({
  onClose,
  record,
}: NursingEstimateDialogProps) {
  const isEditMode = !!record;
  const [formData, setFormData] = useState(() => ({
    time: dayjs(record ? record.date : undefined),
    notes: record ? record.notes : "",
    mls: (record && `${record.mls}`) || "",
    duration: (record && `${record.duration}`) || "",
  }));

  const [createEstimate] = babyTrackerApi.useCreateNursingEstimateMutation();
  const [updateEstimate] = babyTrackerApi.useUpdateNursingEstimateMutation();
  const [deleteEstimate] = babyTrackerApi.useDeleteNursingEstimateMutation();
  const { enqueueSnackbar } = useSnackbar();
  const onError = useOnErrorNotification();

  const onSave = useCallback(async () => {
    const fields = {
      notes: formData.notes,
      date: formData.time.toISOString(),
      mls: +formData.mls,
      duration: +formData.duration,
    };

    try {
      if (isEditMode) {
        await updateEstimate({
          ...fields,
          id: record.id,
        });
        enqueueSnackbar("Estimate updated", {
          variant: "success",
        });
      } else {
        await createEstimate(fields);
        enqueueSnackbar("Estimate created", {
          variant: "success",
        });
      }
    } catch (error) {
      onError(error);
    } finally {
      onClose();
    }
  }, [
    formData,
    createEstimate,
    updateEstimate,
    enqueueSnackbar,
    isEditMode,
    onClose,
    onError,
    record,
  ]);

  const onDelete = useCallback(
    () =>
      deleteEstimate(record.id).then(() => {
        enqueueSnackbar("Note deleted", {
          variant: "success",
        });
        onClose();
      }),
    [record, enqueueSnackbar, deleteEstimate, onClose]
  );

  return (
    <StandardFormDialog
      fullWidth
      title={isEditMode ? "Edit note" : "Take note"}
      edit={isEditMode}
      onClose={onClose}
      value={formData}
      onChange={setFormData}
      fields={[
        {
          type: "datetime",
          maxDateTime: dayjs(),
          label: "Start date",
          dataKey: "time",
        },
        {
          type: "number",
          label: "ml consumed",
          dataKey: "mls",
        },
        {
          type: "number",
          label: "duration (minutes)",
          dataKey: "duration",
        },
        {
          type: "multiline",
          label: "Notes",
          dataKey: "notes",
        },
      ]}
      onDelete={onDelete}
      onSave={onSave}
    />
  );
}
