import React, { useRef } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import {
  formatLastBottleFeed,
  formatLastBreastFeed,
} from "../../util/formatting";
import { babyTrackerApi } from "../../data/babyTrackerApi";
import { useAppSelector } from "../../data/rootReducer";
import { feedingStatsSelector } from "../../data/selectors/dataSelectors";
import {
  useOpenBottleFeedDialog,
  useOpenBreastFeedDialog,
} from "../../hooks/dialogHooks";
import { useNavigate } from "react-router-dom";
import useOnLongPress from "../../hooks/useOnLongPress";

export default function FeedingStatsCard() {
  const { isLoading, isError, data } = babyTrackerApi.useGetAllFeedsQuery();
  const feedingStats = useAppSelector(feedingStatsSelector);
  const openBottleFeed = useOpenBottleFeedDialog(data?.[0]);
  const openBreastFeed = useOpenBreastFeedDialog(data?.[0]);
  const navigate = useNavigate();
  const cardRef = useRef<HTMLButtonElement>();
  useOnLongPress(cardRef, () => {
    navigate("/records/feed");
  });

  const openFeed = () =>
    Number.isFinite(data?.[0]?.minutesOnBreast)
      ? openBreastFeed()
      : openBottleFeed();

  if (isLoading || !feedingStats) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Failed to get feeding stats</div>;
  }

  return (
    <Card>
      <CardActionArea
        data-long-press-delay="1000"
        ref={cardRef}
        onClick={openFeed}
      >
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Feeding
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {formatLastBottleFeed(feedingStats.lastBottleFeeding)}
            <br />
            {formatLastBreastFeed(feedingStats.lastBreastFeeding)}
          </Typography>
          <hr />
          <Typography variant="body2" color="text.secondary">
            {feedingStats.last3hours.bottle}mls,{" "}
            {feedingStats.last3hours.breast}m on breast - last 3 hours
            <br />
            {feedingStats.last24hours.bottle}mls,{" "}
            {feedingStats.last24hours.breast}m on breast - last 24 hours
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
