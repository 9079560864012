import { Card, CardContent, Link, Stack, Typography } from "@mui/material";
import React from "react";

export default function UsefulLinks() {
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Links
        </Typography>
        <Stack spacing={1} direction="column">
          <Link
            href="https://healthychildren.org/"
            target="_blank"
          >
            The AAP Parenting Website
          </Link>
          <Link
            href="https://www.pediatricsleepcoach.com/post/witching-hour"
            target="_blank"
          >
            Witching hour
          </Link>
          <Link
            href="https://practicallyperfectbaby.com/anti-snacking/"
            target="_blank"
          >
            Snacking cycle
          </Link>
        </Stack>
      </CardContent>
    </Card>
  );
}
