import { Button, Stack } from "@mui/material";
import { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import DateTimeField from "./DateTimeField";
import DurationField from "./DurationField";
import MultilineTextField from "./MultilineTextField";
import Multiselect from "./Multiselect";
import MultiselectCheckbox from "./MultiselectCheckbox";
import TextField from "./TextField";
import TimeField from "./TimeField";
import SingleSelect, { Option } from "./SingleSelect";

interface BaseFormField {
  label: string;
  dataKey: string;
  disabled?: boolean;
}

interface TextFormField extends BaseFormField {
  type: "text" | "password" | "multiline" | "number";
  inputMode?: React.HTMLAttributes<unknown>["inputMode"];
}

interface SingleSelectFormField extends BaseFormField {
  type: "select";
  options: string[] | Option[];
}

interface SelectFormField extends BaseFormField {
  type: "multiselect" | "multiselect-cb";
  options: string[];
}

interface TimeFormField extends BaseFormField {
  type: "time";
  maxTime?: Dayjs;
}

interface DateTimeFormField extends BaseFormField {
  type: "datetime";
  maxDateTime?: Dayjs;
}

interface ImageFormField extends BaseFormField {
  type: "image";
}

interface DurationFormField extends BaseFormField {
  type: "duration";
}

type FormField =
  | TextFormField
  | SelectFormField
  | DateTimeFormField
  | TimeFormField
  | ImageFormField
  | DurationFormField
  | SingleSelectFormField;

export interface StandardFormProps<T> {
  fields: FormField[];
  value: T;
  onChange: (value: T) => void;
}

interface StandardFormFieldProps {
  field: FormField;
  value: any;
  onChange: (value: any) => void;
}

interface FileFromDataProps {
  data: string | File;
}

const readFileToDataUrlAsync = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (evt) => resolve(evt.target.result as string);
    fileReader.onerror = reject;
    fileReader.readAsDataURL(file);
  });

function FileFromData({ data }: FileFromDataProps) {
  const [url, setUrl] = useState("");
  useEffect(() => {
    if (typeof data === "string") {
      setUrl(data);
    } else {
      readFileToDataUrlAsync(data).then(setUrl);
    }
  }, [data]);

  return (
    <img
      src={url}
      style={{
        maxWidth: "100%",
        maxHeight: "80px",
        verticalAlign: "bottom",
        objectFit: "cover",
      }}
    />
  );
}

function StandardFormField({ field, value, onChange }: StandardFormFieldProps) {
  switch (field.type) {
    case "number":
    case "text":
      return (
        <TextField
          label={field.label}
          inputMode={field.inputMode}
          value={value}
          onChange={onChange}
          type={field.type}
          disabled={field.disabled}
        />
      );
    case "multiline":
      return (
        <MultilineTextField
          label={field.label}
          value={value}
          onChange={onChange}
          disabled={field.disabled}
        />
      );
    case "select":
      return (
        <SingleSelect
          label={field.label}
          value={value}
          onChange={onChange}
          options={field.options}
          disabled={field.disabled}
        />
      );
    case "multiselect":
      return (
        <Multiselect
          label={field.label}
          value={value}
          onChange={onChange}
          options={field.options}
          disabled={field.disabled}
        />
      );
    case "multiselect-cb":
      return (
        <MultiselectCheckbox
          label={field.label}
          value={value}
          onChange={onChange}
          options={field.options}
        />
      );
    case "time":
      return (
        <TimeField
          label={field.label}
          value={value}
          onChange={onChange}
          maxTime={field.maxTime}
        />
      );
    case "datetime":
      return (
        <DateTimeField
          label={field.label}
          value={value}
          onChange={onChange}
          maxDateTime={field.maxDateTime}
        />
      );
    case "image":
      return !value ? (
        <>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={(evt) => {
              const file = evt.currentTarget.files[0];
              onChange(file);
            }}
          />
          <label htmlFor="raised-button-file">
            <Button variant="contained" component="span">
              {field.label}
            </Button>
          </label>
        </>
      ) : (
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <div>
            <FileFromData data={value} />
          </div>
          <Button variant="contained" onClick={() => onChange(undefined)}>
            Remove
          </Button>
        </Stack>
      );
    case "duration":
      return (
        <DurationField label={field.label} value={value} onChange={onChange} />
      );
  }
}

export default function StandardForm<T>({
  fields,
  value,
  onChange,
}: StandardFormProps<T>) {
  const getOnChange = (dataKey: string) => (val: any) =>
    onChange({ ...value, [dataKey]: val });

  return (
    <Stack spacing={2} paddingTop={1}>
      {fields.map((field) => (
        <StandardFormField
          field={field}
          key={field.dataKey}
          onChange={getOnChange(field.dataKey)}
          value={value[field.dataKey]}
        />
      ))}
    </Stack>
  );
}
