import dayjs from "dayjs";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { timeSlice } from "../data/timeSlice";

export function useSyncTimeWithStore() {
  const dispatch = useDispatch();
  useEffect(() => {
    const onTick = () => {
      dispatch(timeSlice.actions.updateTime(dayjs().unix() / 60));
    };
    const timerId = setInterval(onTick, 30000);
    onTick();
    return () => clearInterval(timerId);
  }, [dispatch]);
}
