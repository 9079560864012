import React, { useState } from "react";
import dayjs from "dayjs";
import StandardFormDialog from "./StandardFormDialog";
import { useSnackbar } from "notistack";
import { useOnErrorNotification } from "../../hooks/notifications";
import { Sleep } from "../../api/tracker";
import { dialogSlice } from "../../data/dialogSlice";
import { useDispatch } from "react-redux";
import { babyTrackerApi } from "../../data/babyTrackerApi";

export interface SleepDialogProps {
  record?: Sleep;
}

export default function SleepDialog({ record }: SleepDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const onError = useOnErrorNotification();
  const [createSleep] = babyTrackerApi.useCreateSleepMutation();
  const [updateSleep] = babyTrackerApi.useUpdateSleepMutation();
  const [deleteSleep] = babyTrackerApi.useDeleteSleepMutation();
  const onCreate = ({ value, notes, date }) =>
    createSleep({
      durationMinutes: value,
      notes,
      date: date.toISOString(),
    }).then(() => {
      enqueueSnackbar("Sweepy recorded", {
        variant: "success",
      });
    }, onError);
  const onUpdate = ({ notes, value, date }) =>
    updateSleep({
      notes,
      durationMinutes: value,
      date: date.toISOString(),
      id: record.id,
    });
  const isEdit = !!record;
  const onSave = isEdit ? onUpdate : onCreate;
  const valueKey = "durationMinutes";
  const [formData, setFormData] = useState(() => {
    if (!record) {
      return {
        time: dayjs(),
        value: "",
        notes: "",
      };
    }

    return {
      time: dayjs(record.date),
      value: record[valueKey],
      notes: record.notes,
    };
  });

  const dispatch = useDispatch();

  const onClose = () => dispatch(dialogSlice.actions.closeDialog());

  return (
    <StandardFormDialog
      title="How long did Rita sweepy?"
      onClose={onClose}
      edit={isEdit}
      fullWidth
      onSave={async () => {
        const value = +formData.value;
        try {
          await onSave({
            value,
            notes: formData.notes,
            date: formData.time.toDate(),
          });
        } catch (error) {
          onError(error);
        }

        onClose();
      }}
      fields={[
        {
          type: isEdit ? "datetime" : "time",
          maxTime: dayjs(),
          label: "Time",
          dataKey: "time",
        },
        {
          type: "duration",
          label: "Sweepy",
          dataKey: "value",
        },
        {
          type: "multiline",
          label: "Notes (optional)",
          dataKey: "notes",
        },
      ]}
      onDelete={async () => {
        try {
          await deleteSleep(record.id);
          enqueueSnackbar("Record removed", {
            variant: "success",
          });
          onClose();
        } catch (error) {
          onError(error);
        }
      }}
      value={formData}
      onChange={setFormData}
    />
  );
}
