import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import React from "react";

interface MultiselectCheckboxProps {
  label: string;
  value: string[];
  onChange: (value: string[]) => void;
  options: string[];
}

export default function MultiselectCheckbox({
  value,
  onChange,
  options,
  label,
}: MultiselectCheckboxProps) {
  return (
    <FormControl>
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={value.includes(option)}
                onChange={(evt) => {
                  const lessThisOption = value.filter((v) => v !== option);
                  if (evt.target.checked) {
                    lessThisOption.push(option);
                  }
                  onChange(lessThisOption);
                }}
              />
            }
            label={option}
            key={option}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}
