import { BrowserRouter } from "react-router-dom";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { store } from "./data/rootReducer";
import { authWithAirtable } from "./api/tracker";
import "long-press-event";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const DingSound = new Audio("/sounds/correct.mp3");

if (navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then((regs) => {
    for (const reg of regs) {
      reg.unregister();
    }
  });
}

const theme = createTheme();

const root = createRoot(document.querySelector("#app"));

const renderApp = () => {
  root.render(
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <CssBaseline />
          <SnackbarProvider
            maxSnack={3}
            classes={{
              containerRoot: "snack-root",
            }}
            onEntered={() => DingSound.play()}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <App />
            </LocalizationProvider>
          </SnackbarProvider>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

authWithAirtable().finally(renderApp);
