import React, { useEffect, useState } from "react";
import { Stack, TextField as MuiTextField } from "@mui/material";

interface DurationFieldProps {
  label: string;
  value: number;
  onChange: (value: number) => void;
}

const numberOrZero = (val: string) => {
  const num = +val;
  return Number.isFinite(num) ? num : 0;
};

function DurationField({ label, value, onChange }: DurationFieldProps) {
  const [minutesInput, setMinutesInput] = useState(() => `${value % 60}`);
  const [hoursInput, setHoursInput] = useState(
    () => `${Math.floor(value / 60)}`
  );

  useEffect(() => {
    const hours = numberOrZero(hoursInput);
    const minutes = numberOrZero(minutesInput);

    const totalMinutes = hours * 60 + minutes;

    onChange(totalMinutes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoursInput, minutesInput]);

  const onMinutesChanged = (
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setMinutesInput(evt.target.value);
  };

  const onHoursChanged = (
    evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setHoursInput(evt.target.value);
  };

  return (
    <Stack>
      <MuiTextField
        margin="dense"
        variant="outlined"
        label={`${label} (hours)`}
        type="number"
        value={hoursInput}
        inputProps={{
          inputMode: "decimal",
        }}
        onChange={onHoursChanged}
      />
      <MuiTextField
        margin="dense"
        variant="outlined"
        label={`${label} (minutes)`}
        type="number"
        value={minutesInput}
        inputProps={{
          inputMode: "decimal",
        }}
        onChange={onMinutesChanged}
      />
    </Stack>
  );
}

export default DurationField;
