import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { Sleep as SleepRecord } from "../../api/tracker";
import { babyTrackerApi } from "../../data/babyTrackerApi";
import { dialogSlice } from "../../data/dialogSlice";
import { useVirtualizedArray } from "../../hooks/virtualScroll";
import { durationToHours, hoursToDuration } from "../../util/duration";
import { getDisplayTime } from "../../util/formatting";

export default function Sleep() {
  const { isLoading, isError, data } = babyTrackerApi.useGetAllSleepsQuery();
  const dispatch = useDispatch();

  const [updateSleep] = babyTrackerApi.useUpdateSleepMutation();
  const [deleteSleep] = babyTrackerApi.useDeleteSleepMutation();

  const { truncatedArray, endElement } = useVirtualizedArray(data, 50);

  const getOnEditSleep = (record: SleepRecord) => () => {
    dispatch(
      dialogSlice.actions.openSleepDialog({
        title: "How long did Rita sweepy?",
        inputTitle: "Hours sweepied",
        valueKey: "durationMinutes",
        decodeValue: durationToHours,
        encodeValue: hoursToDuration,
        onDelete: deleteSleep,
        onSave: ({ notes, value, date }) =>
          updateSleep({
            notes,
            durationMinutes: value,
            date: date.toISOString(),
            id: record.id,
          }),
        record,
      })
    );
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Failed to load sleep log</div>;
  }

  return (
    <>
      <Paper variant="elevation" square>
        <Stack direction="row" alignItems="center" paddingX={2} paddingY={1}>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Sweepy records
          </Typography>
        </Stack>
      </Paper>
      <Stack gap={1} padding={1}>
        {truncatedArray.map((sleep) => (
          <Card key={sleep.id}>
            <CardActionArea onClick={getOnEditSleep(sleep)}>
              <CardContent>
                <Stack direction="row" marginBottom={1} alignItems="center">
                  <Typography variant="h6" component="span">
                    {getDisplayTime(sleep.date)}
                  </Typography>
                  <Typography sx={{ paddingLeft: 1 }}>
                    {+(sleep.durationMinutes / 60).toFixed(2)} hours
                  </Typography>
                </Stack>
                <Typography>{sleep.notes}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
        {endElement}
      </Stack>
    </>
  );
}
