import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const chartSlice = createSlice({
  name: "chart",
  initialState: {
    chartType: "Line",
    chartDuration: 7,
  },
  reducers: {
    setChartType(state, { payload }: PayloadAction<string>) {
      state.chartType = payload;
    },
    setChartDuration(state, { payload }: PayloadAction<number>) {
      state.chartDuration = payload;
    },
  },
});
