import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { IoAdd } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { babyTrackerApi } from "../../data/babyTrackerApi";
import { dialogSlice } from "../../data/dialogSlice";
import { getDisplayTime } from "../../util/formatting";

export default function NursingEstimates() {
  const { isLoading, isError, data } =
    babyTrackerApi.useGetAllNursingEstimatesQuery();
  const dispatch = useDispatch();

  const onNewEstimate = useCallback(() => {
    dispatch(dialogSlice.actions.openNursingEstimateDialog());
  }, [dispatch]);

  const getOnEditEstimate = (record) => () => {
    dispatch(
      dialogSlice.actions.openNursingEstimateDialog({
        record,
      })
    );
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Failed to load nursing estimates</div>;
  }

  return (
    <>
      <Paper variant="elevation" square>
        <Stack direction="row" alignItems="center" paddingX={2} paddingY={1}>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Nursing Estimates
          </Typography>
          <IconButton onClick={onNewEstimate}>
            <IoAdd />
          </IconButton>
        </Stack>
      </Paper>
      <Stack gap={1} padding={1}>
        {data.map((note) => (
          <Card key={note.id}>
            <CardActionArea onClick={getOnEditEstimate(note)}>
              <CardContent>
                <Stack direction="row" marginBottom={1}>
                  <Typography variant="h6" component="span">
                    {getDisplayTime(note.date)}
                  </Typography>
                  <Typography sx={{ paddingLeft: 1 }}>
                    {note.mls}mls every {note.duration} minutes
                  </Typography>
                </Stack>
                <Typography>{note.notes}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Stack>
    </>
  );
}
