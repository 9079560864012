import {
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { Pump } from "../../api/tracker";
import { babyTrackerApi } from "../../data/babyTrackerApi";
import { dialogSlice } from "../../data/dialogSlice";
import { useVirtualizedArray } from "../../hooks/virtualScroll";
import { getDisplayTime } from "../../util/formatting";

export default function Pumps() {
  const { isLoading, isError, data } = babyTrackerApi.useGetAllPumpsQuery();
  const dispatch = useDispatch();

  const [deletePump] = babyTrackerApi.useDeletePumpMutation();
  const [updatePump] = babyTrackerApi.useUpdatePumpMutation();

  const { truncatedArray, endElement } = useVirtualizedArray(data, 50);

  const getOnEditPump = (record: Pump) => () => {
    dispatch(
      dialogSlice.actions.openPumpDialog({
        title: "How did pumping go?",
        inputTitle: "Volume pumped? (ml)",
        valueKey: "pumpedMls",
        onDelete: deletePump,
        onSave: ({ date, notes, value }) =>
          updatePump({
            id: record.id,
            date: date.toISOString(),
            notes,
            pumpedMls: value,
          }),
        record,
      })
    );
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Failed to load pumps</div>;
  }

  return (
    <>
      <Paper variant="elevation" square>
        <Stack direction="row" alignItems="center" paddingX={2} paddingY={1}>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Pump records
          </Typography>
        </Stack>
      </Paper>
      <Stack gap={1} padding={1}>
        {truncatedArray.map((pump) => (
          <Card key={pump.id}>
            <CardActionArea onClick={getOnEditPump(pump)}>
              <CardContent>
                <Stack direction="row" marginBottom={1} alignItems="center">
                  <Typography variant="h6" component="span">
                    {getDisplayTime(pump.date)}
                  </Typography>
                  <Typography sx={{ paddingLeft: 1 }}>
                    {pump.pumpedMls}ml
                  </Typography>
                </Stack>
                <Typography>{pump.notes}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
        {endElement}
      </Stack>
    </>
  );
}
