import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { GiPauseButton, GiSoundWaves } from "react-icons/gi";
import { homeAutomationApi } from "../../data/homeAutomationApi";

export default function WhiteNoiseCard() {
  const { data: playerState, refetch } =
    homeAutomationApi.useGetPlayerStateQuery();
  const [pausePlayer] = homeAutomationApi.usePausePlayerMutation();
  const [startPlayer] = homeAutomationApi.usePlayWhiteNoiseMutation();

  useEffect(() => {
    let interval: number;
    if (playerState && playerState.state === "transitioning") {
      interval = window.setInterval(refetch, 1000);
    }
    return () => clearInterval(interval);
  }, [playerState, refetch]);

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          White noise machine - {playerState ? playerState.state : "loading..."}
        </Typography>
        <Stack spacing={1} direction="row">
          {playerState &&
          (playerState.state === "paused" ||
            playerState.state === "stopped") ? (
            <Button
              onClick={() => startPlayer()}
              variant="contained"
              color="inherit"
              startIcon={<GiSoundWaves />}
            >
              Play white noise
            </Button>
          ) : (
            <Button
              onClick={() => pausePlayer()}
              variant="contained"
              color="inherit"
              startIcon={<GiPauseButton />}
            >
              Pause
            </Button>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
