import React from "react";
import { TextField as MuiTextField } from "@mui/material";

interface TextFieldProps {
  label: string;
  value: string;
  type?: React.HTMLInputTypeAttribute;
  inputMode?: React.HTMLAttributes<unknown>["inputMode"];
  disabled?: boolean;
  onChange: (val: string) => void;
}

export default function TextField({
  label,
  value,
  type,
  inputMode,
  onChange,
  disabled,
}: TextFieldProps) {
  return (
    <MuiTextField
      margin="dense"
      variant="outlined"
      label={label}
      type={type}
      value={value}
      inputProps={{
        inputMode,
      }}
      disabled={disabled}
      onChange={(evt) => onChange(evt.target.value)}
    />
  );
}
