import { Stack } from "@mui/material";
import React from "react";
import Timer from "../card/Timer";
import UsefulLinks from "../card/UsefulLinks";
import WhiteNoiseCard from "../card/WhiteNoiseCard";

export default function Utils() {
  return (
    <Stack padding={1} spacing={1}>
      <WhiteNoiseCard />
      <Timer />
      <UsefulLinks />
    </Stack>
  );
}
