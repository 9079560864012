import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import {
  GiAbacus,
  GiBabyBottle,
  GiNightSleep,
  GiOilPump,
} from "react-icons/gi";
import { useNavigate } from "react-router-dom";

export default function Records() {
  const navigate = useNavigate();

  return (
    <>
      <Typography variant="h5" sx={{ marginTop: 1, marginLeft: 1 }}>
        Records
      </Typography>
      <List disablePadding>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/records/feed")}>
            <ListItemIcon>
              <GiBabyBottle />
            </ListItemIcon>
            <ListItemText>Feeds</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/records/pumps")}>
            <ListItemIcon>
              <GiOilPump />
            </ListItemIcon>
            <ListItemText>Pumps</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => navigate("/records/sleep")}>
            <ListItemIcon>
              <GiNightSleep />
            </ListItemIcon>
            <ListItemText>Sweepy</ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => navigate("/records/nursing-estimates")}
          >
            <ListItemIcon>
              <GiAbacus />
            </ListItemIcon>
            <ListItemText>Nursing Estimates</ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );
}
