import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { IoAdd } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { getBaseUrl } from "../../api/tracker";
import { babyTrackerApi } from "../../data/babyTrackerApi";
import { dialogSlice } from "../../data/dialogSlice";
import { getDisplayTime } from "../../util/formatting";

export default function Notes() {
  const { isLoading, isError, data } = babyTrackerApi.useGetAllNotesQuery();
  const dispatch = useDispatch();

  const onNewNote = useCallback(() => {
    dispatch(dialogSlice.actions.openNoteDialog());
  }, [dispatch]);

  const getOnEditNote = (record) => () => {
    dispatch(
      dialogSlice.actions.openNoteDialog({
        record,
      })
    );
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <div>Failed to load notes</div>;
  }

  return (
    <>
      <Paper variant="elevation" square>
        <Stack direction="row" alignItems="center" paddingX={2} paddingY={1}>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Notes
          </Typography>
          <IconButton onClick={onNewNote}>
            <IoAdd />
          </IconButton>
        </Stack>
      </Paper>
      <Stack gap={1} padding={1}>
        {data.map((note) => (
          <Card key={note.id}>
            <CardActionArea onClick={getOnEditNote(note)}>
              <CardContent>
                <Stack direction="row" marginBottom={1}>
                  <Typography variant="h6" component="span">
                    {getDisplayTime(note.date)}
                  </Typography>
                  <Box
                    marginLeft={1}
                    sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                  >
                    {(note.tags || []).map((tag) => (
                      <Chip key={tag} label={tag} />
                    ))}
                  </Box>
                </Stack>
                {note.imageId != null && (
                  <img
                    style={{ maxWidth: "100%" }}
                    src={`${getBaseUrl()}/files/file/${note.imageId}`}
                  />
                )}
                <Typography>{note.notes}</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
      </Stack>
    </>
  );
}
