import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import DialogCloseButton from "../button/DialogCloseButton";
import StandardForm, { StandardFormProps } from "../form/StandardForm";

interface StandardFormDialogProps<T> extends StandardFormProps<T> {
  title: string;
  open?: boolean;
  edit?: boolean;
  fullWidth?: boolean;
  onClose: () => void;
  onSave: () => void;
  onDelete?: () => void;
}

export default function StandardFormDialog<T>({
  open = true,
  title,
  value,
  onChange,
  fields,
  edit = false,
  fullWidth = false,
  onClose,
  onDelete,
  onSave,
}: StandardFormDialogProps<T>) {
  return (
    <Dialog open={open} fullWidth={fullWidth}>
      <DialogTitle>
        {title}
        <DialogCloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <StandardForm fields={fields} value={value} onChange={onChange} />
      </DialogContent>
      <DialogActions>
        {edit && onDelete && (
          <Button onClick={onDelete} color="error">
            Delete
          </Button>
        )}
        <Button onClick={onClose} color="info">
          Cancel
        </Button>
        <Button onClick={onSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
