import { IconButton } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

export default function DialogCloseButton({ onClose }) {
  return (
    <IconButton
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
      onClick={onClose}
    >
      <CloseIcon />
    </IconButton>
  );
}

DialogCloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
};
